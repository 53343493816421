import "moment/min/locales";

import * as React from "react";

import {
  Checkbox,
  ChoiceGroup,
  ComboBox,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";

import { Fabric } from "@fluentui/react/lib/Fabric";
import { Header } from "../_components/Header";
import { IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import ReactDOM from "react-dom";
import { Sidebar } from "../_components/Sidebar";
import i18n from "i18next";
import { userService } from "../_services";

export interface PoliciesPageState {
  userData?: any;
  repoData?: any;
  showLeftPanel: boolean;
  selLink: any;
  syncStatus: any;
  downloadingFiles: any;
  uploadsQueue: any;
  isLoading: boolean;
  goBack: number;
  contextualMenuProps?: IContextualMenuProps;
  breadcrumb: any;
  mfa: string;
  collections: string[];
  disabledApps: string[];
  links: string;
  showRightPanel: boolean;
  warning: string;
  saving: boolean;

  fileAppendI: boolean;
  fileAppendG: boolean;
  folderCreationsI: boolean;
  folderCreationsG: boolean;
  folderUploadsI: boolean;
  folderUploadsG: boolean;
  folderDuplicationsI: boolean;
  folderDuplicationsG: boolean;
}

export interface Props {
  match: {
    url: string;
  };
  location: any;
  appPlatform: string;
  isOnline: boolean;
  maintenance: boolean;
  socket: any;
  pusher: any;
  appUpdateAvailable: boolean;
  webUpdateAvailable: boolean;
  fileExplorerType: string;
  userData: any;
}

export class PoliciesPage extends React.Component<Props, PoliciesPageState> {
  private _isMounted: boolean;
  private headerRef: any = React.createRef();
  private sidebarRef: any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      repoData: null,
      showLeftPanel: true,
      selLink: null,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck",
      },
      downloadingFiles: [],
      uploadsQueue: this._getImports(),
      isLoading: false,
      goBack: 0,
      contextualMenuProps: undefined,
      breadcrumb: [],
      mfa: "",
      collections: [],
      disabledApps: [],
      links: "0",
      showRightPanel: true,
      warning: "",
      saving: false,
      fileAppendI: false,
      fileAppendG: false,
      folderCreationsI: false,
      folderCreationsG: false,
      folderUploadsI: false,
      folderUploadsG: false,
      folderDuplicationsI: false,
      folderDuplicationsG: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.setState({
      breadcrumb: [
        {
          text: "Policies",
          key: "/policies",
          onClick: this.headerRef._onBreadcrumbItemClicked.bind(this),
        },
      ],
    });
    if (!localStorage.openFilePath) localStorage.setItem("openFilePath", "askToOpen");
    if (!localStorage.pdfApplication) localStorage.setItem("pdfApplication", "Default");
  }

  public componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData || (this.props.userData && this.state.mfa === "")) {
      const repo = this.props.userData.repository.id;
      userService
        .getRepo(repo)
        .then((response) => {
          if (response.status === 200) {
            if (response.data["2fa"] === true) {
              this.setState({ mfa: "2" });
            } else {
              this.setState({ mfa: "0" });
            }
            console.log(response.data["collections"]);
            this.setState({ collections: response.data["collections"] ?? [] });
            this.setState({
              disabledApps: response.data["disabledApps"] ?? [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      userService
        .getDefaultSubscriptions(repo)
        .then((response) => {
          for (let i in response.data) {
            if (response.data[i].role === "INTERNAL") {
              let subscriptions = response.data[i].subscriptions;
              for (let s in subscriptions) {
                if (subscriptions[s] === "FILE_APPEND") this._isMounted && this.setState({ fileAppendI: true });
                else if (subscriptions[s] === "FOLDER_CREATIONS")
                  this._isMounted && this.setState({ folderCreationsI: true });
                else if (subscriptions[s] === "FOLDER_UPLOADS")
                  this._isMounted && this.setState({ folderUploadsI: true });
                else if (subscriptions[s] === "FOLDER_DUPLICATIONS")
                  this._isMounted && this.setState({ folderDuplicationsI: true });
              }
            } else if (response.data[i].role === "GUEST") {
              let subscriptions = response.data[i].subscriptions;
              for (let s in subscriptions) {
                if (subscriptions[s] === "FILE_APPEND") this._isMounted && this.setState({ fileAppendG: true });
                else if (subscriptions[s] === "FOLDER_CREATIONS")
                  this._isMounted && this.setState({ folderCreationsG: true });
                else if (subscriptions[s] === "FOLDER_UPLOADS")
                  this._isMounted && this.setState({ folderUploadsG: true });
                else if (subscriptions[s] === "FOLDER_DUPLICATIONS")
                  this._isMounted && this.setState({ folderDuplicationsG: true });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData);
  };

  _toggleMFA(value: string) {
    if (value === "0") {
      // FALSE
      this._isMounted && this.setState({ mfa: value });
    } else if (value === "2") {
      // TRUE
      this._isMounted && this.setState({ mfa: value });
    }
  }

  private save() {
    const repo = this.props.userData.repository.id;
    this._isMounted && this.setState({ saving: true, warning: "" });
    const repoData = {
      "2fa": this.state.mfa === "2",
      collections: this.state.collections,
      disabledApps: this.state.disabledApps,
    };
    userService
      .updateRepo(repo, repoData)
      .then((response) => {
        //this._isMounted && this.setState({mfa: this.state.mfa, saving: false, warning: 'Policies updated.'})
      })
      .catch((error) => {
        console.log(error);
        this._isMounted && this.setState({ saving: false, warning: "Error changing multiple factor authentication" });
        setTimeout(() => {
          this._isMounted && this.setState({ warning: "" });
        }, 4000);
      });

    let internalSubs: string[] = [];
    if (this.state.fileAppendI) internalSubs.push("FILE_APPEND");
    else
      internalSubs = internalSubs.filter((item) => {
        return item !== "FILE_APPEND";
      });
    if (this.state.folderCreationsI) internalSubs.push("FOLDER_CREATIONS");
    else
      internalSubs = internalSubs.filter((item) => {
        return item !== "FOLDER_CREATIONS";
      });
    if (this.state.folderUploadsI) internalSubs.push("FOLDER_UPLOADS");
    else
      internalSubs = internalSubs.filter((item) => {
        return item !== "FOLDER_UPLOADS";
      });
    if (this.state.folderDuplicationsI) internalSubs.push("FOLDER_DUPLICATIONS");
    else
      internalSubs = internalSubs.filter((item) => {
        return item !== "FOLDER_DUPLICATIONS";
      });

    let guestSubs: string[] = [];
    if (this.state.fileAppendG) guestSubs.push("FILE_APPEND");
    else
      guestSubs = guestSubs.filter((item) => {
        return item !== "FILE_APPEND";
      });
    if (this.state.folderCreationsG) guestSubs.push("FOLDER_CREATIONS");
    else
      guestSubs = guestSubs.filter((item) => {
        return item !== "FOLDER_CREATIONS";
      });
    if (this.state.folderUploadsG) guestSubs.push("FOLDER_UPLOADS");
    else
      guestSubs = guestSubs.filter((item) => {
        return item !== "FOLDER_UPLOADS";
      });
    if (this.state.folderDuplicationsG) guestSubs.push("FOLDER_DUPLICATIONS");
    else
      guestSubs = guestSubs.filter((item) => {
        return item !== "FOLDER_DUPLICATIONS";
      });

    userService
      .updateDefaultSubscriptions(repo, "INTERNAL", internalSubs)
      .then((response) => {
        userService
          .updateDefaultSubscriptions(repo, "GUEST", guestSubs)
          .then((response) => {
            this._isMounted && this.setState({ saving: false, warning: "Policies updated." });
            setTimeout(() => {
              this._isMounted && this.setState({ warning: "" });
            }, 4000);
          })
          .catch((error) => {
            console.log(error);
            this._isMounted && this.setState({ saving: false, warning: "Error updating Guest users subscriptions" });
            setTimeout(() => {
              this._isMounted && this.setState({ warning: "" });
            }, 4000);
          });
      })
      .catch((error) => {
        console.log(error);
        this._isMounted && this.setState({ saving: false, warning: "Error updating Internal users subscriptions" });
        setTimeout(() => {
          this._isMounted && this.setState({ warning: "" });
        }, 4000);
      });
  }

  public render() {
    const possibleCollections = [
      {
        key: "KYC_FISICO",
        text: "Fisico",
      },
      {
        key: "KYC_JURIDICO",
        text: "Juridico",
      },
      {
        key: "PROJECT",
        text: "Project",
      },
      {
        key: "REALSTATE_DOCUMENT",
        text: "Real State Document",
      },
      {
        key: "CLIENT_CONTRACT",
        text: "Client Contract",
      },
    ];

    const possibleApps = [
      {
        key: "EMAILS",
        text: "Email",
      },
      {
        key: "CALENDAR",
        text: "Calendar",
      },
      {
        key: "CHATS",
        text: "Chats",
      },
      {
        key: "TASKS",
        text: "Tasks",
      },
      {
        key: "NOTES",
        text: "Notes",
      },
    ];

    return (
      <Fabric>
        <Header
          ref={(instance) => {
            this.headerRef = instance;
          }}
          headerSection="policies"
          appPlatform={this.props.appPlatform}
          isOnline={this.props.isOnline}
          maintenance={this.props.maintenance}
          pusher={this.props.pusher}
          repoData={this.state.repoData}
          folderId={this.props.match["params"].id}
          syncStatus={this.state.syncStatus}
          downloadingFiles={[]}
          uploadsQueue={[]}
          getCurrentContent={this._getCurrentContent.bind(this)}
          fileExplorerType={this.props.fileExplorerType}
          sidebarRef={this.sidebarRef}
          breadcrumb={this.state.breadcrumb}
          callbackFunction={this.callbackFunction}
          showRightPanel={this.state.showRightPanel}
        />
        <div className="d-flex flex-row">
          <Sidebar
            ref={(instance) => {
              this.sidebarRef = instance;
            }}
            appPlatform={this.props.appPlatform}
            isOnline={this.props.isOnline}
            maintenance={this.props.maintenance}
            appUpdateAvailable={this.props.appUpdateAvailable}
            webUpdateAvailable={this.props.webUpdateAvailable}
            userData={this.props.userData}
            downloadingFiles={this.state.downloadingFiles}
            uploadsQueue={this.state.uploadsQueue}
            headerRef={this.headerRef}
            searchQuery={this.props.location.search}
            fileExplorerType={this.props.fileExplorerType}
            callbackFunction={this.callbackFunction}
            sidebarNav="policies"
            headerSection="policies"
          />
          <div className={"list mr-auto flex-grow-1 " + this.props.appPlatform}>
            <div className={"list policies mr-auto flex-grow-1 " + this.props.appPlatform}>
              <div className="mx-3 pt-3">
                <span style={{ fontWeight: 600, fontSize: 16 }}>Authentication</span>
              </div>
              <div className="m-3">
                <ChoiceGroup
                  label={i18n.t("policies:2fa") + ":"}
                  options={[
                    {
                      key: "0",
                      text: "Disabled",
                    },
                    {
                      key: "1",
                      text: "Enabled per user",
                      disabled: true,
                    },
                    {
                      key: "2",
                      text: "Enabled for all",
                    },
                  ]}
                  selectedKey={this.state.mfa}
                  onChange={(e, v: any) => {
                    this._toggleMFA(v.key);
                  }}
                />
              </div>
              <hr />
              <div className="mx-3">
                <span style={{ fontWeight: 600, fontSize: 16 }}>Collections</span>
              </div>
              <div className="m-3" style={{ width: "250px" }}>
                <ComboBox
                  multiSelect
                  options={possibleCollections}
                  selectedKey={this.state.collections}
                  onChange={(e, option) => {
                    if (option) {
                      const updatedCollections = option.selected
                        ? [...this.state.collections, option.key as string]
                        : this.state.collections.filter((key) => key !== option.key);
                      this._isMounted && this.setState({ collections: updatedCollections });
                    }
                  }}
                  dropdownWidth={250}
                />
              </div>
              <hr />
              <div className="mx-3">
                <span style={{ fontWeight: 600, fontSize: 16 }}>Disabled Apps</span>
              </div>
              <div className="m-3" style={{ width: "250px" }}>
                <ComboBox
                  multiSelect
                  options={possibleApps}
                  selectedKey={this.state.disabledApps}
                  onChange={(e, option) => {
                    if (option) {
                      const updatedDisabledApps = option.selected
                        ? [...this.state.disabledApps, option.key as string]
                        : this.state.disabledApps.filter((key) => key !== option.key);
                      this._isMounted && this.setState({ disabledApps: updatedDisabledApps });
                    }
                  }}
                  dropdownWidth={250}
                />
              </div>
              <hr />
              <div className="mx-3">
                <span style={{ fontWeight: 600, fontSize: 16 }}>Public Links</span>
              </div>
              <div className="m-3">
                <ChoiceGroup
                  label={"Public Links expiration time:"}
                  disabled
                  options={[
                    {
                      key: "0",
                      text: "One Week",
                    },
                    {
                      key: "1",
                      text: "Two Weeks",
                    },
                    {
                      key: "2",
                      text: "One month",
                    },
                    {
                      key: "3",
                      text: "Custom",
                    },
                  ]}
                  selectedKey={this.state.links}
                  onChange={(e, v: any) => {
                    this._isMounted && this.setState({ links: v.key || "0" });
                  }}
                />
                <div className="mt-2" style={{ maxWidth: "200px" }}>
                  <TextField type="number" placeholder="Number of weeks" disabled={this.state.links !== "3"} />
                </div>
              </div>
              <hr />
              <div className="mx-3">
                <span style={{ fontWeight: 600, fontSize: 16 }}>Sharing Requests</span>
              </div>
              <div className="m-3">
                <p style={{ fontWeight: 600 }}>Requests for Editor:</p>
                <Checkbox disabled className="mt-2" label={"Sharing internal folders"} />
                <Checkbox disabled className="mt-2" label={"Sharing external folders"} />
                <Checkbox disabled className="mt-2" label={"Sharing public links"} />
              </div>
              <div className="m-3">
                <p style={{ fontWeight: 600 }}>Requests for Restricted Editor:</p>
                <Checkbox disabled className="mt-2" label={"Sharing internal folders"} />
                <Checkbox disabled className="mt-2" label={"Sharing external folders"} />
                <Checkbox disabled className="mt-2" label={"Sharing public links"} />
              </div>
              <hr />
              <div className="mx-3">
                <span style={{ fontWeight: 600, fontSize: 16 }}>Default subscriptions for new users</span>
              </div>
              <div className="subscribeNotifications m-3">
                <div className="d-flex align-items-center category">
                  <Label className="category-header">{i18n.t("policies:internalUsers")}</Label>
                  <span className="category-header2">Instant email</span>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FILE_APPEND")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FILE_APPEND"
                      onChange={(e) => this._isMounted && this.setState({ fileAppendI: !this.state.fileAppendI })}
                      checked={this.state.fileAppendI}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FOLDER_CREATIONS")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FOLDER_CREATIONS"
                      onChange={(e) =>
                        this._isMounted && this.setState({ folderCreationsI: !this.state.folderCreationsI })
                      }
                      checked={this.state.folderCreationsI}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FOLDER_UPLOADS")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FOLDER_UPLOADS"
                      onChange={(e) => this._isMounted && this.setState({ folderUploadsI: !this.state.folderUploadsI })}
                      checked={this.state.folderUploadsI}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FOLDER_DUPLICATIONS")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FOLDER_DUPLICATIONS"
                      onChange={(e) =>
                        this._isMounted && this.setState({ folderDuplicationsI: !this.state.folderDuplicationsI })
                      }
                      checked={this.state.folderDuplicationsI}
                    />
                  </div>
                </div>

                {/*<div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FOLDER_RENAME')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FOLDER_RENAME"
                        onChange={(e) => this.onChangeSubscription(e, "FOLDER_RENAME")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FOLDER_MOVE')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FOLDER_MOVE"
                        onChange={(e) => this.onChangeSubscription(e, "FOLDER_MOVE")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FOLDER_DELETE')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FOLDER_DELETE"
                        onChange={(e) => this.onChangeSubscription(e, "FOLDER_DELETE")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FOLDER_DOWNLOAD')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FOLDER_DOWNLOAD"
                        onChange={(e) => this.onChangeSubscription(e, "FOLDER_DOWNLOAD")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FILE_APPEND')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FILE_APPEND"
                        onChange={(e) => this.onChangeSubscription(e, "FILE_APPEND")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FILE_DELETE')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FILE_DELETE"
                        onChange={(e) => this.onChangeSubscription(e, "FILE_DELETE")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FILE_DOWNLOAD')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FILE_DOWNLOAD"
                        onChange={(e) => this.onChangeSubscription(e, "FILE_DOWNLOAD")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FILE_MINOR_VERSION')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FILE_MINOR_VERSION"
                        onChange={(e) => this.onChangeSubscription(e, "FILE_MINOR_VERSION")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FILE_MAJOR_VERSION')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FILE_MAJOR_VERSION"
                        onChange={(e) => this.onChangeSubscription(e, "FILE_MAJOR_VERSION")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category'>
                    <span className='category-label'>{i18n.t('policies:FILE_MOVE')}</span>
                    <div className='checkbox'>
                      <Checkbox
                        key="FILE_MOVE"
                        onChange={(e) => this.onChangeSubscription(e, "FILE_MOVE")}
                      />
                    </div>
                  </div>

                  <div className='d-flex align-items-center category last'>
                    <span className='category-label'>{i18n.t('policies:FILE_RENAME')}</span>
                    <div className='checkbox last'>
                      <Checkbox
                        key="FILE_RENAME"
                        onChange={(e) => this.onChangeSubscription(e, "FILE_RENAME")}
                      />
                    </div>
                  </div>*/}

                <div className="d-flex align-items-center category mt-4">
                  <Label className="category-header">{i18n.t("policies:guestUsers")}</Label>
                  <span className="category-header2">Instant email</span>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FILE_APPEND")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FILE_APPEND"
                      onChange={(e) => this._isMounted && this.setState({ fileAppendG: !this.state.fileAppendG })}
                      checked={this.state.fileAppendG}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FOLDER_CREATIONS")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FOLDER_CREATIONS"
                      onChange={(e) =>
                        this._isMounted && this.setState({ folderCreationsG: !this.state.folderCreationsG })
                      }
                      checked={this.state.folderCreationsG}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FOLDER_UPLOADS")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FOLDER_UPLOADS"
                      onChange={(e) => this._isMounted && this.setState({ folderUploadsG: !this.state.folderUploadsG })}
                      checked={this.state.folderUploadsG}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center category">
                  <span className="category-label">{i18n.t("policies:FOLDER_DUPLICATIONS")}</span>
                  <div className="checkbox">
                    <Checkbox
                      key="FOLDER_DUPLICATIONS"
                      onChange={(e) =>
                        this._isMounted && this.setState({ folderDuplicationsG: !this.state.folderDuplicationsG })
                      }
                      checked={this.state.folderDuplicationsG}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="align-items-center policies-footer">
              <div className="mx-3 flex-grow-1">
                {this.state.warning && this.state.warning.length > 0 && (
                  <MessageBar onDismiss={() => this.setState({ warning: "" })} messageBarType={MessageBarType.success}>
                    {this.state.warning}
                  </MessageBar>
                )}
              </div>
              <PrimaryButton
                className=""
                onClick={this.save.bind(this)}
                disabled={this.state.saving}
                text={i18n.t("policies:save")}
              />
            </div>
          </div>
        </div>
      </Fabric>
    );
  }

  private _getCurrentContent() {
    this._isMounted &&
      this.setState({
        syncStatus: {
          message: "Syncing",
          type: "info",
          icon: "spinner",
        },
        isLoading: true,
      });
  }

  private _getImports() {
    if (localStorage.getItem("uploadsQueue")) {
      return JSON.parse(localStorage.getItem("uploadsQueue") || "{}");
    } else {
      return [];
    }
  }

  private _policiesUpdated = async () => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;
      var confirm = () => {
        showDialog = false;
        renderDialog();
        resolve(true);
      };

      var div = document.createElement("div");
      var renderDialog = () => {
        ReactDOM.render(
          <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>Polices updated</div>,
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog",
            }}
            onDismiss={() => {
              showDialog = false;
              renderDialog();
            }}
          >
            <div className="dialog-content-wrap">
              <DialogFooter className="mt-4">
                <PrimaryButton autoFocus onClick={confirm} text="OK" />
              </DialogFooter>
            </div>
          </Dialog>,
          div
        );
      };

      renderDialog();
    });
  };

  private onChangeSubscription = (e, key) => {};
}
