import { authHeader, history } from "../_helpers";

//import { authHeader } from '../_helpers';
import axios from "axios";
import i18n from "../i18n";
import uuid from "uuid-random";

var sessionDuration = 30; //Same duration as the token
var canRefreshToken = true;

export const userService = {
  getPublicLinkUrl,
  login,
  verifyEmailCode,
  resendVerifyEmailCode,
  afterLogin,
  setServerDomain,
  logout,
  getUserData,
  getRepoUsers,
  getExternalRepoUsers,
  getExternalRepoUsersWaitingApproval,
  createRepoUser,
  createMultipleRepoUsers,
  editRepoUser,
  resendActivationEmail,
  restoreRepoUser,
  deleteRepoUser,
  getGroups,
  createGroup,
  getGroup,
  editGroup,
  deleteGroup,
  getGroupPossibleUsers,
  getActivity,
  searchActivity,
  getFolderDetails,
  refreshToken,
  sendApproval,
  getRepo,
  updateRepo,
  getSessions,
  deleteSession,
  getServerInfo,
  getServerInfoCompany,
  getDefaultSubscriptions,
  updateDefaultSubscriptions,
  getConversations,
  saveAttachment,
  getFolderContent,
  search,
  getBookmarks,
  getRecentFiles,
  getRecentfolders,
  getPathFolders,
  listFoldersLevels,
  getConversationMessages,
  downloadConversationAttachment,
  createMessage,
  updateMessage,
  deleteMessage,
};

// const artifactApi = process.env.REACT_APP_ARTIFACT_API_URL;
// const indexerApi = process.env.REACT_APP_INDEXER_API_URL;
const synergyApi = process.env.REACT_APP_SYNERGY_API_URL;
var serverDomain = localStorage.getItem("serverdomain") || "eu-dev.synergyos.com";
var membershipApi = synergyApi + serverDomain + process.env.REACT_APP_MEMBERSHIP_API_URL;
var activityApi = synergyApi + serverDomain + process.env.REACT_APP_ACTIVITY_API_URL;
var artifactApi = synergyApi + serverDomain + process.env.REACT_APP_ARTIFACT_API_URL;

function getInstanceId() {
  var instanceId = localStorage.getItem("instanceId");
  if (!instanceId) {
    let origin = "Origin-" + uuid();
    localStorage.setItem("instanceId", origin);
    instanceId = origin;
  }
  return instanceId;
}

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
}

function toTimestamp(strDate: any) {
  var datum = Date.parse(strDate);
  return datum;
}

function getPublicLinkUrl(publiclinkToken: string, deploymentDomain: string) {
  var url = "https://link." + deploymentDomain + "/download/" + publiclinkToken + "/" + serverDomain;
  return url;
}

function login(username, password, domain: string, staySignedIn: boolean) {
  let instanceId = getInstanceId();
  console.log(instanceId);
  const requestOptions = {
    application: "Admin center",
    domain: domain,
    email: username,
    origin: instanceId,
    password: password,
    staySignedIn: staySignedIn,
  };

  return axios
    .post(membershipApi + "/v2/public/admin/login", requestOptions, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleLoginResponse(error.response || error);
    });
}

function getUserData() {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/v2/auth/introspect-token", headersOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw handleLoginResponse(error.response || error);
    });
}

function verifyEmailCode(token: string, code: string, staySignedIn: boolean) {
  const headersOptions = {
    headers: { Authorization: token },
  };

  var instanceId = staySignedIn ? "?origin=" + getInstanceId() : "";

  return axios
    .post(membershipApi + "/auth/2FA/" + code + instanceId, {}, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleLoginResponse(error.response || error);
    });
}

function resendVerifyEmailCode(email, password, companyDomain) {
  const requestOptions = {
    domain: companyDomain,
    email: email,
    password: password,
  };

  return axios
    .post(membershipApi + "/v2/public/auth/2fa/resend", requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

async function afterLogin(response, staySignedIn) {
  var token = response.data.accessToken;
  var daysToExpire = 9; //Used by wopi
  var expirationDate = addDays(new Date(), daysToExpire);
  var tokenttl = toTimestamp(expirationDate);
  if (staySignedIn) {
    //localStorage.setItem('refreshKey', response.data.refreshToken.refreshKey)
  }

  var sessionExpirationDate = new Date();
  sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);

  localStorage.setItem("user", token);
  localStorage.setItem("tokenttl", tokenttl.toString());
  localStorage.setItem("serverdomain", serverDomain);

  return response;
}

function setServerDomain(url: string) {
  var promise = new Promise((resolve, reject) => {
    serverDomain = url;
    membershipApi = synergyApi + serverDomain + process.env.REACT_APP_MEMBERSHIP_API_URL;
    activityApi = synergyApi + serverDomain + process.env.REACT_APP_ACTIVITY_API_URL;
    localStorage.setItem("serverdomain", serverDomain);
    resolve(url);
  });
  return promise;
}

function getServerDomain() {
  return serverDomain;
}

function logout() {
  // remove user from local storage to log user out
  const headersOptions = {
    headers: authHeader(),
  };

  axios.delete(membershipApi + "/v2/auth/logout", headersOptions);
  localStorage.removeItem("user");
  history.push("/login");
}

function getRepoUsers(repositoryRole: string, users?: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  let userQuery = users || "";
  let role = repositoryRole === "ALL" ? "" : "&role=" + repositoryRole;

  return axios
    .get(membershipApi + "/v2/users?size=99999" + userQuery + role, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getExternalRepoUsers(repoId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/externals/users/" + repoId, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getExternalRepoUsersWaitingApproval(repoId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/externals/users/approve/" + repoId, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function createRepoUser(email: string, name: string, surname: string, role: string, infrastructureDomain: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  const requestOptions = {
    name: name,
    surname: surname,
    email: email,
    role: role,
    infrastructureDomain: infrastructureDomain,
  };

  return axios
    .post(membershipApi + "/v2/admin/users/", requestOptions, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function createMultipleRepoUsers(users: any[], repositoryId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  for (var i = 0; i < users.length; i++) {
    delete users[i]["id"];
  }

  return axios
    .post(membershipApi + "/auth/add/user/" + repositoryId + "?domain=" + serverDomain, users, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function editRepoUser(id: string, name: string, surname: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  const requestOptions = {
    name: name,
    surname: surname,
  };

  return axios
    .put(membershipApi + "/v2/admin/users/" + id, requestOptions, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function resendActivationEmail(userId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .post(
      membershipApi + "/v2/admin/users/" + userId + "/resend-activation/",
      { infrastructureDomain: serverDomain },
      headersOptions
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function restoreRepoUser(repoId: string, userId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .post(
      membershipApi + "/v2/admin/users/" + userId + "/restore",
      { infrastructureDomain: serverDomain },
      headersOptions
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function deleteRepoUser(repoId: string, userId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .delete(membershipApi + "/v2/admin/users/" + userId, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getGroups(repositoryRole) {
  const headersOptions = {
    headers: authHeader(),
  };
  let role = repositoryRole === "ALL" ? "" : "&role=" + repositoryRole;
  return axios
    .get(membershipApi + "/v2/groups?size=99999" + role, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function createGroup(name: string, description: string, users: any, repositoryRole: string = "INTERNAL") {
  const headersOptions = {
    headers: authHeader(),
  };

  const requestOptions = {
    name: name,
    description: "",
    users: users,
    repositoryRole: repositoryRole,
  };

  return axios
    .post(membershipApi + "/v2/groups", requestOptions, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getGroup(id: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/v2/groups/" + id, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function editGroup(id: string, name: string, description: string, users: any) {
  const headersOptions = {
    headers: authHeader(),
  };

  const requestOptions = {
    name: name,
    description: description,
    users: users,
  };

  return axios
    .put(membershipApi + "/v2/groups/" + id, requestOptions, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function deleteGroup(id: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .delete(membershipApi + "/v2/groups/" + id, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getGroupPossibleUsers(groupId?: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/v2/groups/" + groupId + "/available-users", headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getActivity(
  repo: string,
  user_id: string | any,
  model_type: string | any,
  operation_type: string | any,
  initialDate: number | any,
  finalDate: number | any,
  paginationResultFrom: number,
  paginationResultSize: number
) {
  const headersOptions = {
    headers: authHeader(),
  };

  // if(initialDate) console.log("Initial date", new Date(parseInt(initialDate)).toLocaleString("en-US"))
  // if(finalDate) console.log("Final date", new Date(parseInt(finalDate)).toLocaleString("en-US"))

  const requestOptions = {
    repo: repo,
    user_id: user_id,
    model_type: model_type,
    operation_type: operation_type,
    initialDate: parseInt(initialDate),
    finalDate: parseInt(finalDate),
    paginationResultFrom: paginationResultFrom,
    paginationResultSize: paginationResultSize,
  };

  return axios
    .post(activityApi + "/activity-feed/search", requestOptions, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function searchActivity(query: string, options: any) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .post(synergyApi + serverDomain + `/activity-feed/v2/activity/search${query}`, options, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getFolderDetails(id) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(artifactApi + "/folders/" + id, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function sendApproval() {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .post(synergyApi + serverDomain + "/email-service/emailservice/request/approval", {}, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getRepo(id) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/v2/admin/repositories/" + id, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function updateRepo(id: string, data) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .put(membershipApi + "/v2/admin/repositories/" + id, data, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getSessions(id) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/v2/admin/repositories/" + id + "/sessions", headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function deleteSession(sessionId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .delete(membershipApi + "/v2/admin/sessions/" + sessionId, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function refreshToken() {
  var refreshKey = localStorage.getItem("refreshKey");
  if (canRefreshToken && refreshKey) {
    canRefreshToken = false;
    return axios
      .post(membershipApi + "/v2/public/auth/refresh-token", { refreshToken: refreshKey })
      .then((response) => {
        console.log("Token refreshed");
        var token = response.data.accessToken;
        var daysToExpire = 9; //Used by wopi
        var expirationDate = addDays(new Date(), daysToExpire);
        var tokenttl = toTimestamp(expirationDate);
        localStorage.setItem("refreshKey", response.data.refreshToken);

        var sessionExpirationDate = new Date();
        sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);

        localStorage.setItem("user", token);
        localStorage.setItem("tokenttl", tokenttl.toString());
        localStorage.setItem("serverdomain", getServerDomain());

        setTimeout(() => {
          canRefreshToken = true;
        }, 10000);
      })
      .catch((error) => {
        setTimeout(() => {
          canRefreshToken = true;
        }, 10000);
        logout();
        history.push("/login");
        console.log("Error trying to refresh token.");
        throw error;
      });
  } else {
    logout();
    history.push("/login");
    console.log("Error trying to refresh token.");
  }
}

function getServerInfo(companyDomain: string) {
  return axios
    .get("https://synergy-api.common.synergy.page/server-info/public/" + companyDomain)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getServerInfoCompany(companyDomain: string) {
  return axios
    .get("https://synergy-api.common.synergy.page/server-info/public/company/" + companyDomain)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getDefaultSubscriptions(repoId: string) {
  const headersOptions = {
    headers: authHeader(),
  };

  return axios
    .get(membershipApi + "/v2/admin/repositories/" + repoId + "/subscriptions", headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function updateDefaultSubscriptions(repoId: string, role: string, subscriptions) {
  const headersOptions = {
    headers: authHeader(),
  };

  const requestOptions = {
    role: role,
    subscriptions: subscriptions,
  };

  return axios
    .put(membershipApi + "/v2/admin/repositories/" + repoId + "/subscriptions", requestOptions, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getConversations(queryString: string = "", page: number = 0) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  const query = `?page=${page}&size=9999` + queryString;

  return axios
    .get(synergyApi + serverDomain + `/workflow-service/conversations${query}`, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function saveAttachment(formData: FormData) {
  const headersOptions: any = {
    headers: authHeader(),
    contentType: "multipart/form-data",
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  };

  return axios
    .post(synergyApi + serverDomain + `/workflow-service/attachments`, formData, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getFolderContent(id) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .get(synergyApi + serverDomain + "/artifact/folders/search/" + id, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function search(query: string) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .get(synergyApi + serverDomain + "/search/search" + query, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getBookmarks(repoId) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .get(synergyApi + serverDomain + "/artifact/bookmarks/getMyBookmarks?reposIds=" + repoId, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getRecentFiles() {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .get(synergyApi + serverDomain + `/activity-feed/activity-feed/recentfiles`, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getRecentfolders() {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .get(synergyApi + serverDomain + "/activity-feed/activity-feed/recentfolders", headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getPathFolders(id: string) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .get(synergyApi + serverDomain + "/artifact/paths/folders/" + id, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function listFoldersLevels(id: string, levels: number) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .get(synergyApi + serverDomain + `/artifact/folders/search/` + id + `/levels/` + levels, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function getConversationMessages(id: string, page: number = 0) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  const query = `?page=${page}&size=50`;

  return axios
    .get(synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages${query}`, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function downloadConversationAttachment(
  conversationId: string,
  conversationMessageId: string,
  attachmentId: string,
  download = false
) {
  const headersOptions: any = {
    headers: authHeader(),
    responseType: download ? "blob" : "arraybuffer",
  };

  return axios
    .get(
      synergyApi +
        serverDomain +
        `/workflow-service/conversations/${conversationId}/messages/${conversationMessageId}/attachments/${attachmentId}`,
      headersOptions
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function createMessage(
  id: string,
  content: string,
  to: string[],
  cc: string[],
  bcc: string[],
  attachments: any[] = []
) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  const requestOptions: any = {
    content: content,
    to: to,
    cc: cc,
    bcc: bcc,
    attachmentIds: attachments,
  };

  return axios
    .post(synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages`, requestOptions, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function updateMessage(id: string, messageId: string, content: string, attachments: any[] = []) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  const requestOptions: any = {
    content: content,
    attachmentIds: attachments,
  };

  return axios
    .put(
      synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages/${messageId}`,
      requestOptions,
      headersOptions
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function deleteMessage(id: string, messageId: string) {
  const headersOptions: any = {
    headers: authHeader(),
  };

  return axios
    .delete(synergyApi + serverDomain + `/workflow-service/conversations/${id}/messages/${messageId}`, headersOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw handleResponse(error.response);
    });
}

function handleLoginResponse(response) {
  if (response) {
    if (response.status === 401 || response.status === 403) {
      // auto logout if 401 or 403 response returned from api
      logout();
      history.push("/login");
      throw response;
    } else if (response.status === 405) {
      window.postMessage(
        {
          msg: "showServiceDialog",
          dialogMessage: i18n.t("app:noPermission"),
        },
        "*"
      );
      throw response;
    } else if (response.status === 503) {
      // Maintenance mode
      window.postMessage(
        {
          msg: "maintenance",
        },
        "*"
      );
      throw response;
    } else {
      throw response;
    }
  }
}

function handleResponse(response: any) {
  if (response) {
    if (response.status === 401 || response.status === 403) {
      // auto logout if 401 or 403 response returned from api
      logout();
      history.push("/login");
      throw response;
    } else {
      throw response;
    }
  }

  if (!response) {
    return "Error: Network Error";
  }
}
